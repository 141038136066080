/* JS MOBILE */

$('head').append('<link rel="stylesheet" href="https://use.typekit.net/thy0ytb.css">');

if ($('#left_menu .bloc_lnk.user.connected.dropdown').length > 0) {
    $(document).on('click tap touch', '.bloc_lnk.user.connected.dropdown', function (e) {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).parent().find('.dropdown.mon-compte').slideUp(300);
        } else {
            $(this).addClass('active');
            $(this).parent().find('.dropdown.mon-compte').slideDown(300);
        }
    });
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {
    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    if(!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {
       from = '';
    }

    if (is_not_produit == true) {
        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {
        loader = $(this.nextElementSibling);
        loader.addClass("loading");

    } else {
        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {
        add_produit_specifique(produit_id, idCouleur, idTaille);

    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id) {
    var id = '';
    prix_id = 'prixU';

    if (from == 'basket' || is_not_produit == true || is_achat_express) {
        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {
            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {
        if (from == 'basket') {
            location.reload();
            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {
            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {
            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier

        } else {
            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        if (loader) {
           loader.parent().removeClass('loading');
           loader.removeClass('loading');

        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {
            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        // DATALAYER V2
        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            pushIntoDatalayer(array_panier[5]);
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');
        var count_bask = $('#cart_top .cart').attr('data-cart');

        $("#cart_top").html(response_basket);
        $("#lightbox_achat_express, .lightbox_achat_express, #shad").hide();

        if($('.page_rayon.rayon_lookbook').length) {
            $('body').css('overflow','');
        }

        newshowmodal( produit_id, is_not_produit );

    } else {
        if (loader) {
            loader.parent().removeClass('loading');

        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {
            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function newUpdatePanier(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur
  ) {

    var couleurs = $("#couleurProd" + i);
    var tailles = $("#tailleProd" + i);
    var res = "";
    var html_err = "";
    var flagError = false;

    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;

    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == "") {
        $("#sizeerror").slideDown();
        return false;

    } else if (couleurs && couleurs.val() == "") {
        res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_couleur");
        flagError = true;

    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""

    ) {
        res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_quantity");
        flagError = true;
    }

    if (flagError) {
        alert(res);
        return false;

    } else {
        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != "none") idCouleur = couleurs.val();
        if (tailles && tailles.val() != "none") idTaille = tailles.val();

        var array_response = [];
        var response = ajax_file(
            path_web +
                "ajax_upd_panier.php?prixu=" +
                prixU +
                "&idprod=" +
                idProduit +
                "&idcouleur=" +
                idCouleur +
                "&idtaille=" +
                idTaille +
                "&qte=" +
                qte +
                "&panierId=" +
                paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                    designation +=
                        " - Taille " +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                        ].text;

                } else if (lastTaille != "") {
                    designation += " - Taille " + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation +=
                        " - " +
                        $('[name="' + idElCouleur + '"]:checked')
                            .get(0)
                            .nextElementSibling.title.trim();

                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;
            // SI il n'y a plus de stock
            if (array_response[1] == "false") {
                $("#size_" + idTaille).addClass("disabled");
                alert(translate("product_no_stock_2"));
                return false;
            }
        }

        return array_response;
    }
}

/* functions */
function changeLocale(locale, uri) {
    setTimeout(function () {
        $.ajax({
            url: path_relative_root + "ajax_get_uri_by_lang.php",
            type: "post",
            data: { lang: locale, url: uri },
            success: function (data) {
                if (data != "") {
                    var force = "";
                    if (uri == "/") {
                        force = "?force";
                    }

                    var href_lang = data + force;

                    if (typeof href_change_lang != "undefined") {
                        href_lang = href_change_lang + href_lang;
                    }

                    location.href = href_lang;
                }
            },
        });
    }, 400);
}

/**
 * Ajout le fonction faqinit()
 * sur mobile
 */
function faqinit(array_questions) {

    $(document).on('change keyup focus', '#faq_search', function () {

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {

            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
            }

        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function () {
        if (!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display', 'none');
        theme.addClass('actif');
        showTabFaq(id, 1)
    });

    $('#theme_page .theme').on('click', function () {
        var id = $(this).data('id');
        showTabFaq(id, 1);
    });
}

function researchFaq(node_id, level, question_id) {

    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({ scrollTop: $(".faq_question_" + question_id + "").offset().top - 100 }, 1000);

}

function executeScroll(scrollHeight) {
    $(document).scroll(function () {
        if ($(this).scrollTop() > scrollHeight) {
            $('#floating_add_basket').removeClass('scrollUp');
            $('#floating_add_basket').addClass('scrollDown');
        }
        else {
            $('#floating_add_basket').removeClass('scrollDown');
            $('#floating_add_basket').addClass('scrollUp');
        }
    });
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp, el) {
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;
    var targetBtn = $(el);

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour + '&choix=no_impression';
    } else {
        data = 'idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;
    }

    $.ajax({
        url: path_relative_root + 'ajax_create_etiquette.php',
        type: 'post',
        data: data,
        success: function (res) {
            if (res.substr(0, 5) == 'false') {
                alert(Translator.translate('ajax_sprintf.php?arg1=js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf && res) {
                    downloadFileBase64(res, 'etiquette_retour_' + idCmdOrder + '.pdf', 'pdf');
                } else {
                    location.reload();
                }

                targetBtn.parent().find('.loader').addClass('loading');

                setTimeout(function() {
                    targetBtn.parent().find('.loader').removeClass('loading');
                }, 2000);
            }
        }
    });
}

function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    var divId = 'popup_numero_retour_' + (multiTransp ? idOrderTransp : idOrder);

    var numDIVObjet = document.getElementById(divId);

    numDIVObjet.style.display = "block";
    document.getElementById("general_shade").style.display = "block";
    document.getElementById("general_shade").style.opacity = "1";

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);


    $("html, body").animate({ scrollTop: 0 }, (speed * 2), function () { });
}

/**
 * Downloads a file from a base64 encoded string.
 *
 * @param {string} base64Data - The base64 encoded data of the file.
 * @param {string} filename - The name of the file to be downloaded.
 * @param {string} [type='zip'] - The MIME type of the file. Defaults to 'zip'.
 */
function downloadFileBase64(base64Data, filename, type = 'zip') {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/' + type });

    const link = document.createElement('a');
    const blobUrl = URL.createObjectURL(blob);
    link.href = blobUrl;
    link.target = '_blank';
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp, numRetour) {

    if (!numRetour) {
        if (typeof idOrderTransp != "undefined")
            numRetour = ajax_file(
                parentPath +
                "ajax_num_etiquette.php?idOrder=" +
                idOrder +
                "&multiTransp=" +
                multiTransp +
                "&idOrderTransp=" +
                idOrderTransp
            );
        else
            numRetour = ajax_file(
                parentPath +
                "ajax_num_etiquette.php?idOrder=" +
                idOrder +
                "&multiTransp=" +
                multiTransp
            );
    }


    if (numRetour != "false") {

        var transporteur = '';
        // Dans le cas du multi transporteur on définie les bloc qui doive s'afficher
        var commentaireRetourObjet = "";
        var divCreateNumRetourObjet = document.getElementById("createNumRetour" + transporteur);
        var divPrintPdfObjet = document.getElementById("printPdf" + transporteur);

        if (document.getElementById("commentaireRetourDiv" + transporteur))
            commentaireRetourObjet = document.getElementById(
                "commentaireRetourDiv"
            ).value;

        if (multiTransp !== 1) {
            if (document.getElementById("commentaireRetour") !== null) {
                commentaireRetourObjet = document.getElementById(
                    "commentaireRetour"
                ).value;
            }
        }

        var divBtnPrintPdf = '#divBtnPrintPdf';
        divBtnPrintPdf += '_' + (multiTransp ? idOrderTransp : idOrder);
        var formId = '#return_products_form';
        formId += '_' + (multiTransp ? idOrderTransp : idOrder);

        var recommand = $(formId + " .recommandation").length;

        var has_errors = false;
        $(formId + " .return_quantity").each(function () {
            var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
            return_choice.removeClass('error');

            if ($(this).val() > 0 && return_choice.val() == '0') {
                has_errors = true;
                return_choice.addClass('error');
            }
        });

        if (!has_errors) {
            if (recommand) {
                $(formId + " .recommandation p span").css("color", "#000");
                $(formId + ' p[id^="liste_motif_retour"] .select').css("border", "none");
                $(formId + " .divBtnPrintPdf").show();
            }

            data = $(formId).serialize();
            data += '&idOrder=' + (multiTransp ? idOrderTransp : idOrder) + '&multiTransp=' + multiTransp + '&return_number=' + numRetour + '&choix=no_impression';

            $.ajax({
                url: parentPath + 'ajax_create_etiquette.php',
                type: 'post',
                data: data,
                success: function (res) {
                    closeMultiShad('popup_numero_retour_' + idOrder);
                    window.location.href = res;
                }
            });
        } else {
            if (recommand) {
                $(formId + " .recommandation p span").css("color", "#a40000");
                $(formId + ' p[id^="liste_motif_retour"] .select').css(
                    "border",
                    "2px solid #a40000"
                );
            }
        }

    }
}

function closeMultiShad(id) {

    // Hide modbox and shade and destroy shade's click event
    $('#' + id).slideUp("slow").queue(function () {

        if ($('#shade').is(":visible")) {
            $('#shade').removeClass('visible');
        }

        if ($('#general_shade').is(":visible")) {
            $('#general_shade').css('display', 'none');
        }
        $("#" + id).removeClass("actif");
        $("#shade").removeClass("actif");
        $("body").removeClass("noscroll");

        $(this).dequeue();
    });
}

// Swiper mobile "Mon compte" menu
$(function () {
    var menuInitialSlide = 0;
    $('.menu_account_slider_mob .swiper-slide').each(function (i, obj) {
        if ($(obj).hasClass('actif')) {
            menuInitialSlide = i;
        }
    });
    var menuAccSwiperMob = new Swiper(".menu_account_slider_mob", {
        slidesPerView: 2.925,
        spaceBetween: 0,
        slidesOffsetBefore: 0,
        centeredSlides: true,
        initialSlide: menuInitialSlide,
        navigation: {
            nextButton: '.swiper-button-nexts',
            prevButton: '.swiper-button-prevs',
        },
    });

    //parrainage
    var intro_parrainage = translate('intro_parrainage');
    var getButton = $('body.parrainage_index .bloc_parrainage_form');
    getButton.attr('id', 'add_friend')
    var moveBtn3 = getButton.find('.w-submit-button:nth-of-type(3)').attr('id', 'send_invite');
    var moveBtn2 = getButton.find('.w-submit-button:nth-of-type(2)').attr('id', 'send_invite');
    moveBtn3.appendTo($('body.parrainage_index .bloc_parrainage_form + .elem_parrainage'));
    moveBtn2.appendTo($('body.parrainage_index .bloc_parrainage_form + .elem_parrainage'));
    $('.w-submit-button#send_invite').css('opacity', '1');

    $('#bloc_tableau_parrainage table:nth-child(2n) tr td').attr('width', '33.33%');

    if ($('body').hasClass('parrainage_index') && $('#parrainage_form').length) {
        $('<p class="intro_parrainage"><span>' + intro_parrainage + '</span></p>').insertBefore($('#parrainage_form'));
    }

    if ($('body').hasClass('category') && !$('.item_container').hasClass('full') && readCookie('nb_produits') != 2) {
        $('.toggleNumObjects #oneProduct').click();
    }

    if ($('body').hasClass('contact_faq')) {
        let sService = $('#faq_question');
        let fNumCmd = $('#contactNumCommande');

        sService.on('change', function () {
            if ($(this).val() == 1) {
                fNumCmd.show();
            } else {
                fNumCmd.hide();
            }
        });
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
                $('.misc_cookiesinfo #shadCookie').removeClass('actif');
            }, 500);
        }
    });
});

setTimeout(function () {
    $(function () {
        // Footer accordion
        $('.footer_accordion_ctn .title, .accordion_footer #listes h2, .accordion_footer #listes .title').each(function () {
            $(this).click(function () {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $(this).parent().find('.footer_navs, table').removeClass('active').slideUp(300);
                }
                else {
                    $('.footer_accordion_ctn .title, .accordion_footer #listes h2, .accordion_footer #listes .title').removeClass('active');
                    $('.footer_accordion_ctn .footer_navs, #listes table').removeClass('active').slideUp(300);
                    $(this).addClass('active');
                    $(this).parent().find('.footer_navs, table').addClass('active').slideDown(300).css('display', 'block');
                }
            });
        });
    });

    $(".accordion_footer #listes h2 a, .accordion_footer #listes .title a").removeAttr("href");

}, 1000);

// Order detail total product
$("#total_prdct").click(function () {
    $(this).toggleClass("closed");
    $(".cart_item_container").slideToggle("slow");
});

if ($('.bandeauMenuAffix').length > 0) {
    $('body').addClass('bandeau_preheader_on');
    $('header.banner_wrapper').css('top', '11.7vmin');
}

$(document).scroll(function () {
    if ($(this).scrollTop() > 250) {
        $('body.product_page .bandeauMenuAffix, body.product_page #site_head').css({ 'opacity': '1', 'z-index': '30' });
        $('body.product_page').css('padding-top', '17.6vmin');
    }
    else {
        $('body.product_page .bandeauMenuAffix, body.product_page #site_head').css({ 'opacity': '0', 'z-index': '-1' });
        $('body.product_page').css('padding-top', '0');
    }
    if ($(this).scrollTop() > 900) {
        $('#floating_add_basket').removeClass('scrollUp');
        $('#floating_add_basket').addClass('scrollDown');
        $('body.product_page footer').css('padding-bottom', '4rem');
    }
    else {
        $('#floating_add_basket').removeClass('scrollDown');
        $('#floating_add_basket').addClass('scrollUp');
        $('body.product_page footer').css('padding-bottom', '0');
    }

    if ($('#left_menu.open').length > 0) {
        $('body.product_page .bandeauMenuAffix, body.product_page #site_head').css({ 'opacity': '1', 'z-index': '30' });
    }

    if ($(this).scrollTop() === 0) {
        $('header.banner_wrapper').css('top', '11.7vmin');
      }
});

if ($(window).width() == 320) {
    executeScroll(400);
};
if ($(window).width() == 375) {
    executeScroll(450);
};
if ($(window).width() == 414) {
    executeScroll(680);
};
if ($(window).width() == 568) {
    executeScroll(680);
};
if ($(window).width() == 667) {
    executeScroll(780);
};
if ($(window).width() == 736) {
    executeScroll(950);
};
if ($(window).width() == 812) {
    executeScroll(1250);
};

if ($('#popup_numero_retour').length == 0) {
    $('.btn_container .button').click(function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active').next("div").removeClass("active");
            $(this).next("div").slideToggle();
        }
        else {
            $(this).addClass('active').next("div").addClass("active");
            $(this).next("div").slideToggle();
        }
    });
}

// Sends message
function sendMessage(id, message_send, message_fail) {
    var errbox = document.getElementById('item_error');
    var form = $('#form_reply')[0];
    var fd = new FormData(form);
    var objElement = document.getElementById("loader_1");
    var item_error = document.getElementById('item_error');
    if ($('#msg_txt_' + id).val() == '') {

        $('.modbox_title', errbox).html("Veuillez saisir votre réponse");
        objElement.style.display = "none";
        item_error.style.display = "block";
        openModalBox.call(errbox);
        return false;
    }
    if ($('#msg_txt_' + id).val() != '') {
        fd.append('message', $('#msg_txt_' + id).val());
        $.ajax({
            type: 'post',
            url: path_relative_root + 'message-detail.php',
            data: fd,
            processData: false,  // dire à jQuery de ne pas traiter les données
            contentType: false,   // dire à jQuery de ne pas définir le contentType
            cache: false,
            success: function (response) {

                if (response == '1') {
                    item_error.style.display = "block";
                    $('.modbox_title', errbox).html(message_send);
                    modBoxOpen.call(errbox);
                    window.setTimeout(function () {
                        window.location.reload();
                    }, 3000);

                } else {
                    $('.modbox_title', errbox).html(message_fail);
                    modBoxOpen.call(errbox);
                }
            }
        });
    } else {
        $('.modbox_title', errbox).html(message_fail);
        modBoxOpen.call(errbox);
    }
}

function addFileInput2(id_input, string) {

    var field = (typeof (string) != 'undefined') ? string : 'contactProduitPhoto';

    id = parseInt(id_input);
    var next_file = id + 1;
    var file = $('#' + field + id_input)[0].files[0];
    var error = false;
    var input = field == 'contactProduitPhoto' ? $('#photoProduit_display') : $('#photoProduitEndommage_display');
    var nb_input = field == 'contactProduitPhoto' ? $(input).val() : $(input).val();
    var reset_file = document.getElementById('reset_file');
    nb_input = parseInt(nb_input);
    var size = typeof file != "undefined" ? file.size : 0;
    var type = typeof file != "undefined" ? file.type : '';
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autoris�s
    if (size > 4000000) {
        alert('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(type, allowedType) == -1) {
        alert('Format autorisé: jpg, png, pdf');
        error = true;
    }
    if (error) {
        if (id_input == 1) {
            $('#field_send').addClass('cache');
            $('#' + field + next_file).remove();
        }
        $('#' + field + id_input).val('');
    } else {

        if (id_input == 1) {
            $('#field_send').removeClass('cache');
            if (field == 'filepj') {
                $('.msgBox .file_msg p').text($('#filepj1')[0].files[0].name);
                reset_file.style.display = "block";
            }
            if (field == 'contactProduitPhoto') {
                $('.msgBox .file_msg p').text($('#contactProduitPhoto1')[0].files[0].name);
                reset_file.style.display = "block";
            }

            if (field == 'contactProduitPhotoEndommage') {
                $('.msgBox .file_msg p').text($('#contactProduitPhotoEndommage1')[0].files[0].name);
                reset_file.style.display = "block";
            }
        }
        if (field != "filepj") {
            $(input).attr("value", next_file);
        }

    }
}

function resetFile(id_input) {

    if (id_input == "filepj1") {
        var file = document.getElementById('filepj1');
        var reset_file = document.getElementById('reset_file');
        reset_file.style.display = "none";
        $('.msgBox .file_msg p').text('');
        file.value = '';
    }
    if (id_input == "imagepj1") {
        var image = document.getElementById('imagepj1');
        var reset_image = document.getElementById('reset_image');
        reset_image.style.display = "none";
        $('.msgBox .image_msg p').text('');
        image.value = '';
    }

    if (id_input == 'contactProduitPhotoEndommage') {
        var file2 = document.getElementById('contactProduitPhotoEndommage1');
        var reset_file2 = document.getElementById('reset_file');
        reset_file2.style.display = "none";
        $('.msgBox .file_msg p').text('');
        file2.value = '';
    }

    if (id_input == 'contactProduitImageEndommage') {
        var image2 = document.getElementById('contactProduitImageEndommage1');
        var reset_image2 = document.getElementById('reset_image');
        reset_image2.style.display = "none";
        $('.msgBox .image_msg p').text('');
        image2.value = '';
    }

}

var eresa_no_stock = true;

// Eresa CTA : Checks if size selected then shows pop in eresa
function checkCartEresa() {

    var form = document.getElementById('item_add_form'),
        shad = document.getElementById('general_shade'),
        itm_id = (form.itm_id) ? form.itm_id.value : null,
        value = getInputVal.bind(form),
        head_offset = (window.innerWidth * 0.2921875),
        form_offset = $('.prod_page_bot', '#content_product').get(0).offsetTop,
        data, has_errors;

    if (!itm_id) {

        return false;
    }
    data = {
        mobile: 1,
        product_id: itm_id,
        size_id: value('itm_size'),
        color_id: value('itm_colour'),
        need_color_id: !!form.itm_colour,
        need_size_id: !!form.itm_size,
        is_model: value('itm_is_model')
    };
    form.itm_colour.classList.remove('error');
    form.itm_size.classList.remove('error');
    has_errors = false;

    if ((data.need_color_id && !data.color_id) || (data.need_size_id && !data.size_id)) {

        $('html, body').animate({ scrollTop: form_offset - head_offset }, 400);

        if (!data.color_id) {

            form.itm_colour.classList.add('error');
        }

        if (!data.size_id) {

            form.itm_size.classList.add('error');
        }

        return false;
    }
}

var eresa_v2 = true;
function eresaReady() {
    checkCartEresa();

    if (checkCartEresa() !== false) {
        Eresa.show();
    }
}

/* HOME */

/* INSTAGRAM */
$(".social-module .home-column.bloc_text").insertAfter(".social-module #socialSwiper .social-media-container .insta-link:first-child");

/* SWIPER */

setTimeout(function () {
    var superHomeSwiper = new Swiper('#super_home_swiper.swiper-container ', {
        slidesPerView: 1,
        autoHeight: true,
        loop: true,
        nextButton: ".super_home_swiper_nxt",
        prevButton: ".super_home_swiper_prv",
        pagination: ".swiper-pagination",
        paginationClickable: true,
        paginationType: 'fraction',
        renderFraction: function (currentClassx, totalClassx) {
            return '<span class="' + currentClassx + '"></span>' + ' <span>-</span> ' + '<span class="' + totalClassx + '"></span>';
        }
    });

    if ($('.pushProductHomeWrapper .swiper-slide').length >= 2) {
        var pushProductHomeSwipper = new Swiper('.pushProductHomeWrapper.swiper-container ', {
            slidesPerView: 1,
            loop: false,
            nextButton: ".swiper-pushProduct-button",
            pagination: ".swiper-pagination_2",
            paginationClickable: true,
            paginationType: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return '<span class="' + currentClass + '"></span>' + ' <span>-</span> ' + '<span class="' + totalClass + '"></span>';
            }
        });
    } else {
        $('.pushProductHomeWrapper_main .button_and_pagination').hide();
    }

    var slider_module_mobile = new Swiper('.slider_module_mobile.swiper-container ', {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        loop: false,
        nextButton: ".slider_module_mobile_btn_next",
        prevButton: ".slider_module_mobile_btn_prev",
    });

    var slider_module_mobile_fp = new Swiper('.ranges_carousel_wrapper .swiper-container ', {
        slidesPerView: 1,
        loop: false,
        centeredSlides: false,
        spaceBetween: 20,
        nextButton: ".slider_module_mobile_btn_next",
        prevButton: ".slider_module_mobile_btn_prev",
    });

    if ($('.rayonCategSliders .swiper-slide').length > 1) {
        var rayonInitialSlide;
        $('.rayonCategSliders .swiper-slide').each(function (i, obj) {
            if ($(obj).find('.menuitem').hasClass('actif')) {
                rayonInitialSlide = i;
            }
        });
        var rayonCategSliders = new Swiper('.rayonCategSliders', {
            loop: false,
            slidesPerView: 'auto',
            centeredSlides: true,
            initialSlide: rayonInitialSlide,
            nextButton: '.swiper-button-next.new',
            prevButton: '.swiper-button-prev.new'
        });
    }

    if ($("#sliderBando_slide .swiper-slide").length > 1) {

        var bandoHeadermobile = new Swiper("#sliderBando_slide", {
            slidesPerView: 1,
            loop: true,
            autoplay: 2500,
            speed: 2500,
        });
    }

    if ($(".swiper_publication_mobile .swiper-slide").length) {

        var swiper_publication_mobile = new Swiper(".swiper_publication_mobile", {
            slidesPerView: 'auto',
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
        });
    }

    $(".banner_wrapper .banner_nav>.header_section:not(.logo) , .banner_wrapper .banner_nav #search_icon").wrapAll("<div class='first_div'></div>");

}, 1000);

$(function () {
    var $deja_compte = Translator.translate('existing_user_pwd_reset');
    $(".login_form_wrapper .w-newlogin-form").append($('<p class="deja_compte">' + $deja_compte + '</p>'));

    /* Retirer les semicolon dans chaque h3 classe item dans le pop up ecadeau étape 3 */
    var orderGiftCardFormStr = $("#giftCardAddToCart h3");
    $(orderGiftCardFormStr).each(function () {
        $(this).html($(this).html().replace(":", ""));
    })

    // Changer l'ordre de prenom avec nom sur page info
    var formInput1 = $('body.customer #wrapper_moncompte #clientForm .form_item.wrapper_civility + .form_item');
    var formInput2 = $('body.customer #wrapper_moncompte #clientForm .form_item.wrapper_civility + .form_item + .form_item');
    formInput2.insertBefore(formInput1);

    //Adresse
    $('body.customer #adresseForm .form_submit').wrapAll('<div class="block_btn">');

    //Wishlist
    $('body.wishlist .wishlist_container').each(function () {
        $(this).find(".wish_liste_product #nrArticlesSelected").prependTo(this);
    });

    $('body.wishlist .wishlist_container .wrapper_cadre_product .infos').each(function () {
        $(this).find("div.subtitle").appendTo(this);
    });
    $("body.wishlist .wishlist_container .wrapper_img .wrapper_info_stock").each(function () {
        if ($(this).length > 0) {
            $(this).prev().css("opacity", "0.35");
        }
    });

    // Commande detail
    var checkText = $('body.order .content_commandes .user_address .address_title');
    checkText.each(function () {
        var checkNum = $(this).text().match(/\d/);
        if (checkNum) {
            $(this).parent().addClass('numb');
        } else {
            $(this).parent().addClass('noNumb');
        }
    });

    var addText = $('body.order .content_commandes .user_address.numb .address_info');
    addText.each(function () {
        var addDate = $(this).find('span:first-child');
        $('<span>Date :&nbsp;</span>').prependTo(addDate);
        var addDate = $(this).find('span:nth-child(2)');
        $('<span>Statut :&nbsp;</span>').prependTo(addDate);
    });

    //Seo textes rayons
    if ($('.txt_bandeau').length) {
        setTimeout(function () {
            readMore();
        }, 300);
    }

    //Reposition ariane Rayons
    if ($('.rayonCategSliders').length) {
        $('#ariane_home').insertAfter('.rayonCategSliders');
        $('#ariane_home').css('opacity', '1');
    } else {
        $('#ariane_home').css('opacity', '1');
    }

    $(document).on('click', '.productColorFieldset .choices_list .color .prodColor', function() {
        hideAchatExpress.call(this);
    });
});

function readMore() {
    var txt = $('.txt_bandeau .txt');
    var txtH = $('.txt_bandeau .txt')[0].scrollHeight;
    var showTxt = $('.txt_bandeau .txt_trigger');

    if (!showTxt.hasClass('triggered')) {
        showTxt.addClass('triggered');
        txt.css('height', txtH + 'px');
        showTxt.text(translate('see_less'));
    } else {
        showTxt.removeClass('triggered');
        showTxt.text(translate('read_more'));
        if (txtH > 160) {
            txt.css('height', '160px');
        } else {
            txt.css('height', txtH + 'px');
            showTxt.hide();
        }
    }
}

$('<br>').appendTo('#threshold_fdp_txt');


/* ARIANE EDITO */

if ($('body').hasClass('publication_index')) {
    $("#ariane_home").insertAfter(".wrapper_menu_satellite");
}

/* Retirer les parenthèses dans np_product page panier etape 1 */
$(function () {
    var np_product = $("body.step_1 .nb_products");
    if (np_product.length) {
        var np_product_new = np_product.html().replace("(", "").replace(")", "");

        setTimeout(function () {
            $(np_product).html(np_product_new);
        }, 100);

    };
});

setTimeout(function () {
    if ($("#menu-lamarque-histoire .swiper-slide").length > 1) {
        var swiperMenuHistoire = new Swiper("#menu-lamarque-histoire", {
            slidesPerView: 3,
            spaceBetween: 30,
            breakpoints: {
                540: {
                    slidesPerView: 2
                }
            }
        });
    }
}, 500);


/*/ Add search bar icon in menu mobile top /*/
$('<div id="search_icon" class="header_section search_icon" onclick="showhide_search();"><span></span></div>').insertBefore("header .banner_nav .header_section.logo");

$('#top_search').css('display', 'none');

function showhide_search() {

    // Check if the document is fully loaded before execute the toggle to avoid bug
    if (document.readyState == 'complete') {

        $('#top_search').slideToggle().toggleClass('activated');
        var topSearch = $('#top_search').hasClass('activated');

        if (!$('.top_search_icon').length) {
            $('#top_search').append('<span class="top_search_icon" onclick="showhide_search();"></span>');
        }

        if (topSearch) {

            $("#shade").addClass('visible');
            $("body").removeClass('search_hidden');
            $(".banner_wrapper").css('zIndex', 100);
            $(".banner_wrapper").css('overflow', 'unset');
            $(".bandeauMenuAffix").css('z-index', '65');

        } else {

            $("#shade").removeClass('visible');
            $("body").addClass('search_hidden');
            $(".banner_wrapper").css('zIndex', 105);
            $(".banner_wrapper").css('overflow', 'hidden');
            $(".bandeauMenuAffix").css('z-index', '60');
        }
    }
}

// Toggles side menu of given id comming from given side
function toggleMenu(id) {

    // Check if the document is fully loaded before execute the toggle to avoid bug
    if (document.readyState == 'complete') {

        var menu = document.getElementById(id + '_menu');
        var shad = document.getElementById('shade');
        var iconMenu = document.getElementById('main_menu_btn');

        if (menu) {

            if (menu.classList.toggle('open') && shad) {

                shad.classList.add('visible');
                document.body.classList.add("noscroll");
                shad.classList.add('forMenu');

                if (id == "left") {
                    iconMenu.classList.add("menu_open");
                    shad.classList.add('left_menu_open');
                } else {
                    shad.classList.add('forFilters');
                }
            } else if (shad) {
                shad.classList.remove('visible');
                shad.classList.remove('forMenu');
                shad.classList.remove('forFilters');
                document.body.classList.remove("noscroll");
                if (id == "left") {
                    iconMenu.classList.remove("menu_open");
                    shad.classList.remove('left_menu_open');
                }
            }
        } else if (shad) {

            shad.classList.toggle('visible');

        }
    }
}
function isInViewport(element) {

    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

function initItemSlider(isToggled) {
    if ($('.prodItemSlider').length) {
        var swiperLazy = is_lazy_load ? false : true; // is_lazy_load defined in _app -> views_mobile_new -> main.php
        if (isToggled == true) { // Toggle view is triggerred
            setTimeout(function () {
                $('.prodItemSlider').each(function () {
                    if ($(this).hasClass('initialized')) {
                        this.swiper.update();
                        this.swiper.slideTo(1);
                    }
                });
            }, 200); // 300 equals the css transition timing defined on .item_container
        } else { // From page load and scroll
            $('.prodItemSlider').each(function () {
                if (isInViewport($(this)) && !$(this).hasClass('initialized')) {
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: true,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: '.itemSliderPagination',
                    });
                    $(this).addClass('initialized');
                }
            });
        }
    }
}
/**
 * Load global .item_container slider initialization on window scroll
 */
setTimeout(function () {
    initItemSlider(false);
}, 100);

window.addEventListener("scroll", function () {
    setTimeout(function () {
        initItemSlider(false);
    }, 100);
});

/**
 * Page - La marque histoire
 */
$(function () {

    if ($('body.satellite_histoire').length) {
        var histoirePage = $('body.satellite_histoire');
        var menuHistoire = histoirePage.find('#menu-lamarque-histoire');
        var stickyMenuPos = menuHistoire.offset().top - 150;
        var bannerNav = histoirePage.find('.banner_wrapper');
        var bandeau = histoirePage.find('.bandeauMenuAffix');
        var headerHeight;

        if (bannerNav.length && bandeau.length) {
            headerHeight = (bannerNav.height() + bandeau.height()) - 5;
        } else if (bannerNav.length) {
            headerHeight = (bannerNav.height()) - 5;
        }

        $(window).resize(function () {
            bannerNav = histoirePage.find('.banner_wrapper');
            bandeau = histoirePage.find('.bandeauMenuAffix');

            if (bannerNav.length && bandeau.length) {
                headerHeight = (bannerNav.height() + bandeau.height()) - 5;
            } else if (bannerNav.length) {
                headerHeight = (bannerNav.height()) - 5;
            }
        });

        $(window).scroll(function () {
            addStickyMenu(menuHistoire, stickyMenuPos, headerHeight);
        });

        getMenuLink(menuHistoire);

    }

    function getMenuLink(menuElm) {
        var link = menuElm.find('a');

        link.each(function () {
            $(this).on('click', function () {
                addElmClass($(this).parent(), 'active');
                scrollToElem($($(this).attr('href')));
            });
        });

    }

    function scrollToElem(elemId) {
        $('html, body').animate({
            scrollTop: elemId.offset().top - 200
        }, 1000);
    }

    function addElmClass(elem, elmClass) {
        if (!elem.hasClass(elmClass)) {
            elem.addClass(elmClass).siblings().removeClass(elmClass);
        }
    }

    function addStickyMenu(elm, stickyPos, topPos) {
        var scroll = $(window).scrollTop();

        if (scroll >= stickyPos) {
            elm.addClass('fixed');
            elm.css('top', topPos + 'px');
        } else {
            elm.removeClass('fixed');
            elm.css('top', '');
        }
    }

    if ($(".mobile #ariane_home").length > 0) {
        $(".mobile #ariane_home").insertAfter($(".mobile #menu_account_slider_mob"));
    }
});

setTimeout(function () {
    $('.publication_index #ariane_home').insertAfter($(".publication_index .wrapper_menu_satellite"));
}, 100);

$('body.step_1 .pushBasketWrapper').insertBefore('body.step_1 .bloc_question');

$(function () {

    $('.cartSizeInfoBtn').on('click', function() {
        $(this).next().addClass('loading');
    });

    $('#shade').on('click tap touch', function() {
        closeMultiShad('cartSizeInfoLightbox');
        $('#desc_donation.cloned').remove();
    });
    setTimeout(function () {

        $('#cookiesLightbox section h4').each(function () {
            var cookiesh4 = $(this);
            $(this).replaceWith('<p class="cookiesh4">' + cookiesh4.html() + '</p>');
        });

        $('.lookbook .container_products_lookbook h2:empty').remove();

    }, 500);

    $(document).scroll(function () {
        if ($('body').hasClass('category') && $(document).scrollTop() !== 0) {
            $(".banner_wrapper").css('top', '0');
            $("#completeDiv.AutoCompleteDivListeStyle").addClass('calibrate');
            $(".bandeauMenuAffix").hide();
        } else {
            $("#completeDiv.AutoCompleteDivListeStyle").removeClass('calibrate');
            $(".bandeauMenuAffix").show();
        }
    });

    // Page Bank VIREMENT BANCAIRE
    $("#paiement_virement").append("<div class='radio'></div>");
    $("<span class='click_here'></span>").insertAfter("#paiement_virement h3 span");
    if ($("#paiement_virement h3 span.click_here").length > 0) {
        $("#paiement_virement h3 span.click_here").text(translate('click_here'));
    }

    // FONT: ZoojaLightPro
    $('head').append('<link rel="stylesheet" href=https://use.typekit.net/hlg4iiq.css>');

    /* Page panier */
    var priceTag = 'body.cart.step_1 .order_recap .order_total.shipping_rate .price_tag';
    $(priceTag).text($(priceTag).text().replace('+', ''));

    function bodyPaddingHome() {
        if ($('.bandeauMenuAffix').length > 0) {
            var bandeauMenuAffix = $('.bandeauMenuAffix').outerHeight(true);
        }
        else {
            var bandeauMenuAffix = 0;
        }
        var bannerWrapper = $('.banner_nav').outerHeight(true);
        var getHeight = bandeauMenuAffix + bannerWrapper;
        $('body.homepage').css("padding-top", getHeight - 2);
    }
    bodyPaddingHome();

    $(window).resize(function () {
        bodyPaddingHome();
    });

    /* lookbook */
    var bodyLook = "body.category #contents_wrapper.lookbook .container_products_lookbook #list_item";
    var $span = $(bodyLook + " .item.lookbook");

    for (var i = 0; i < $span.length; i += 5) {
        var $div = $("<div/>", {
            class: 'look_ctn'
        });
        $span.slice(i, i + 5).wrapAll($div);
    }

    $(bodyLook + " .look_ctn:nth-child(odd)").each(function () {
        $(this).find(".item.lookbook:nth-of-type(-n+2)").wrapAll("<div class='bloc_1'></div>");
        $(this).find(".item.lookbook:nth-of-type(n):not(.bloc_1 .item.lookbook)").wrapAll("<div class='bloc_2'></div>");
    });
    $(bodyLook + " .look_ctn:nth-child(even)").each(function () {
        $(this).find(".item.lookbook:nth-of-type(-n+3)").wrapAll("<div class='bloc_1'></div>");
        $(this).find(".item.lookbook:nth-of-type(n):not(.bloc_1 .item.lookbook)").wrapAll("<div class='bloc_2'></div>");
    } );

    /* Move donation container under push container */
    $('body.step_1 #donation_wrapper').insertBefore('body.step_1 .bloc_question');

    if($('.cart #donation_wrapper').length) {
        $('.elem_tunnel.right_side .donation').css('display','block');
    }

    // Add Donation cards lightbox and shad for more info
    $("#donation_cards .learn_more_donation").on('click tap touch', function() {
        $( '#shade' ).addClass( 'visible' );
        // Remove cloned donation div if already present
        $('#desc_donation.cloned').remove();
        // Clone donation div and insert in footer
        $(this).siblings('#desc_donation').clone().addClass('cloned').css('display','block').insertAfter('.copyright');
    });

    // Remove Donation cards lightbox and shad for more info
    $(document).on('click tap touch', '.cart #desc_donation.cloned .btn_close', function() {
        $('#desc_donation.cloned').remove();
        $('#shade').removeClass('visible');
    });

    // Donation cards Tunnel swiper
    if ($(".cart #donation_wrapper #donation_cards .swiper-slide").length > 1) {
        var swiperDonation = new Swiper("#donation_cards", {
            slidesPerView: 1,
            spaceBetween: 30,
            loop: false,
            paginationClickable: true,
            pagination: '.donationSliderPagination',
        });
    }

    // On click scroll down to donation cards
    $('.donation').on('click touchstart', function() {
        $('html, body').animate({
        scrollTop: $('#donation_wrapper').offset().top - 150
        }, 1000);  // Adjust the duration as needed (1000ms = 1 second)
    });
});

/* NEW SWIPER FP */
setTimeout(function () {
    var assoSwiperSlider2 = new Swiper('.swiper-container.asso_product_carousel2', {
        slidesPerView: 1,
        loop: false,
        nextButton: '.slider_module_mobile_btn_next',
        prevButton: '.slider_module_mobile_btn_prev '
    });
}, 500);

function checkProductInWishlist() {
    var productId = $("#produit_id").val();
    var couleurProd = $("#prod_couleur");
    if (couleurProd.length) {
        couleurProd = $("#prod_couleur").val();
    } else {
        couleurProd = 0;
    }
    var tailleProd = $("#prod_taille");
    if (tailleProd.length) {
        tailleProd = $("#prod_taille").val();
    } else {
        tailleProd = 0;
    }

    if (typeof productId != "undefined" && typeof couleurProd != "undefined" && typeof tailleProd != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data: { 'product_id': productId, 'size_id': tailleProd, 'color_id': couleurProd },
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

function changeBtnAddWishlist(in_wishlist) {
    var btn = $("#addToWishlistButton");
    if (typeof (button_add_to_wishlist_initial_click_event) == "undefined") {
        button_add_to_wishlist_initial_click_event = btn.attr("onclick");
    }
    if (in_wishlist) {
        btn.attr("onclick", "window.location.assign(path_relative_root + create_link('wishlist'))");
        btn.removeClass("addToWishlistButton").addClass("existToWishlistButton");
    } else {
        btn.attr("onclick", button_add_to_wishlist_initial_click_event);
        btn.removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }
}

/**** FAQ TUNNEL ****/
$(function () {
    // LAST THEME FAQ
    let elmThemeFaqLast = $('.cart .questions_frequentes .faq_theme_wrapper:last-child');
    let email = elmThemeFaqLast.find('h3.intitule_question span').text();

    elmThemeFaqLast.find('.questions').empty();
    elmThemeFaqLast.find('.questions').append('<a href="mailto:' + email + '" class="accordion_title intitule_question">' + email + '</a>');

});

// Toggles user account menu visibility
function toggleAccountMenu() {
    var $btn = $(this),
        menu = $(document.getElementById('account_menu')),
        marker = $('.marker', menu),
        body = $('body'),
        shad = document.getElementById('shade');
    offset = this.offsetLeft + (0.5 * this.offsetWidth);

    offset += -6;

    marker.css('left', offset);

    if (menu.hasClass('active')) {
        body.removeClass('noscroll');
        $btn.add(menu).removeClass('active');
        shad.classList.remove('visible');
        shad.classList.remove('forMenu');
        shad.classList.remove('account-menu');
    } else {
        body.addClass('noscroll');
        $btn.add(menu).addClass('active');
        shad.classList.add('visible');
        shad.classList.add('forMenu');
        shad.classList.add('account-menu');
    }

    if ($('body').hasClass('product_page')) {
        setTimeout(function() {
            $('#site_head, .bandeauMenuAffix, .account_menu').css({"z-index":"30", "opacity":"1"});
        }, 10);
    } else {
        setTimeout(function() {
            $('#site_head, .bandeauMenuAffix, .account_menu').css({"z-index":"1", "opacity":""});
        }, 10);
    }
}

// Close menu mobile by shade
$(document).on('click','#shade.forMenu', function() {
    $("body").removeClass("noscroll");
    $("#main_menu_btn").removeClass("menu_open");
    $("#shade").removeClass('visible forMenu forFilters');
    $("#left_menu").removeClass('open');
    $("#shade").removeClass('left_menu_open');
});

function modBoxClose(evt) {
    if ($(this).attr('id') === 'choseCouponFreeProduct') {
        var loader = $('#formPanier .loader');

        if (loader.hasClass('loading')) {
            loader.removeClass('loading');
        }
    }

    $("html,body").css('position','initial');

    var shade = $('#shade');
    var modal = ( $('.modbox.open').length ) ? $('.modbox.open') : $('.modal_box.open');

    if( typeof evt !== "undefined" ) evt.preventDefault();

    shade.off('touchend', modBoxClose)
        .removeClass('visible').removeClass('isExpress');

    modal.removeClass('open');

    $('body.category #list_item .item_container form').each(function () {
        var $div_size = $(this).find('.productSizeFieldset');
        if($div_size.hasClass('actif')) {
            $div_size.removeClass('actif');
        }
    });
}

function openMultiShad(id) {
    $("#" + id).addClass("actif");
    $("body").addClass("noscroll");

    if (!$("#shade").hasClass("visible")) {
        $("#shade").addClass("visible");
    }
}

/* Close search when menu is open */
$(document).on('click','#main_menu_btn .menu', function() {
    if ($(".top_search").hasClass("activated")) {
        $(".top_search").removeClass("activated");
        $('.top_search').css('display', 'none');
    }
});

function changeSeoElementWithPagination(res) {
    // This block of code updates the SEO-related link elements in the HTML document.
    let canonical = res[5];
    let prev = res[6];
    let next = res[7];

    $("link[rel^='canonical']").attr("href", path_root + canonical);
    $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);
    $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

    let hrefPrev = $("link[rel^='prev']");
    let hrefNext = $("link[rel^='next']");
    let commentPrev = $('#href_prev');
    let commentNext = $('#href_next');

    changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
    changeSeoElement(next, hrefNext, commentNext, 'next');
}

// Goes to given page
// Works with pager in shop districts
function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    const productLoaded = $('#list_item .item_container:not(".push")').length;
    const maxItems = document.getElementById('totalElems').value;

    if (fromScrollBottom !== undefined && fromScrollTop !== undefined && ((page - 1) >= Math.ceil(maxItems / parseInt($('#nb_prod').val())) || (productLoaded == maxItems))) {
        // Stop infinite scroll if last page reached or all items loaded on page
        $('#is_loading').val('0');
        $('#initial_page').val(page - 1);
        return false;
    }

    if (fromScrollBottom === undefined && fromScrollTop === undefined) {
        //Update loaded page only on normal pagination for ref, not infinite scroll
        $('#loaded_page').val(page);
        $('#is_loading').val('1');
        isclicked = false;
    }

    $('#is_loading').val('0');
    page = page || 1;

    var type = document.getElementById('type_tri').value;
    var idObj = getCurrentIdRayon();
    var bloc_page = page - 1;
    var content_page = '';

    if (fromScrollBottom || fromScrollTop) {
        $(".loader_scroll").fadeTo("fast", 1);
        $('#is_loading').val('0');
    } else {
        $('#initial_page').val(page);
    }

    if (from_type == 'product') {
        generateNewBlocProd(type, idObj, bloc_page, null, null, fromScrollBottom, fromScrollTop);
    } else {
        generateNewBlocSearch(document.getElementById('nb_prod').value, bloc_page, null, fromScrollBottom, fromScrollTop);
    }
}


// Refactoring needed
function generateNewBlocProd(type, idObj, page, nb_product, btn, fromScrollBottom, fromScrollTop) {
    if ($('.see_all')) {
        $('.see_all').addClass('loading');
    }

    var list_id_div = [];

    output = triggerFilter(page, type, idObj, nb_product, 'filters_form');
    var nb_product = nb_product || parseFloat($('#nb_prod').val());

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = page * nb_product;
        is_from_page = true;
    }

    if ($('#oneProduct').hasClass('actif')) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }
    // Add misc values to output object
    output.page = this.page.value;

    output.parentPath = path_relative_root;
    if (!use_new_filter_url) {
        output.idObj = idObj;
        output.type = type;
    }

    if (output.page != 0) {
        output.nb_prod = nb_product;
    } else {
        // Since we are on the first page, we need to reset the number of products to the default value because pushs are set only on the first page and are repeated manually on others
        output.nb_prod = $('#nb_prod_default').val();
    }

    output.is_page = is_from_page;
    output.nb_tot_prod = this.totalElems.value;
    output.object_id = this.object_id.value;

    output.handle_push = 'false';

    if ($('#prevNSAncre').length > 0) {
        let currentPage = $('#initial_page').val();
        output.current_page = parseInt(currentPage);
    }

    if (typeof id_category_super != 'undefined')
        output.id_category_super = id_category_super;

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
        output.multi_object = $('#multi_object').val();
    }

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    /** LEGACY **/
    //Recuperation du tri par prix (descendant ou ascendant) qui passe, malheureusement, par l'url
    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html');
    var pattern2 = new RegExp('\/e([0-9]*)\.html$');
    var tri = '';
    // Si il y a un ordre dans les prix on charge l'id
    if (pattern.test(url)) {
        var res = url.match(pattern);
        tri = res[1][0];
    }
    /** END LEGACY **/
    if (tri != 0) {
        output.tri = tri;
    }

    if (page === 'all') {

        /** LEGACY **/
        hash = location.hash.substr(1);
        if ((/i=[0-9]+/).test(hash)) {
            // Vu que le header est en absolute on le sort du calcul
            var marginTop = $('.banner_wrapper').height() + 10,
                $elem = $('#' + hash.replace('=', '')),
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function () {
                $(window).scrollTop(top);
            }, 1000);
        }

        /** END LEGACY **/
    } else {
        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + 'ajax_reload_products.php',
            type: 'get',
            data: output,
            success: function (res) {
                //var new_elm = document.createElement('div');
                var old_elm = document.querySelector('.list_item');
                var content, itm_length;
                var is_in_wishlist = [];

                if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {

                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();

                itm_length = parseFloat(res[1]);

                changeSeoElementWithPagination(res);

                if ($('.rayonCategSlider').length && output.object_id) {

                    $('.rayonCategSlider .menuitem').removeClass('actif');
                    $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                    var rayonInitialSlide;

                    $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                        if ($(obj).hasClass('actif')) {
                            rayonInitialSlide = i;
                        }
                    });

                    if (typeof rayonCategSlider != 'undefined') {
                        rayonCategSlider.slideTo(rayonInitialSlide);
                    }
                } else {
                    $('.rayonCategSlider .menuitem').removeClass('actif');
                }

                if (content !== '') {
                    // new_elm.className = 'list_item';
                    // new_elm.innerHTML = content;

                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".loader_scroll").css("display", "none");
                    } else {
                        old_elm.innerHTML = "";
                    }

                    if (type_nav_product === "pagination") {
                        $(".loader_scroll").css("display", "none");
                    }

                    document.getElementById('nbr_items').innerHTML = itm_length;
                    document.getElementById('totalElems').value = itm_length;

                    paginate(page + 1);

                    //old_elm.appendChild(new_elm);

                    if (fromScrollTop) {
                        var firstMsg = $('#scroll_items .item_container:first');
                        var curOffset = firstMsg.offset().top - $(document).scrollTop();
                        old_elm.insertAdjacentHTML('afterbegin', content);
                        $(document).scrollTop(firstMsg.offset().top - curOffset);
                        //L'inverse pour le bas
                    } else {
                        console.log(content);
                        var count = (content.match(/item_container/g) || []).length;
                        console.log(count);
                        old_elm.insertAdjacentHTML('beforeend', content);
                    }

                    if (one_product) {
                        $('div.item_container').addClass('full');
                    }

                    if (parseInt(output.nb_tot_prod) > parseInt(output.nb_prod)) {
                        //backToTop();
                    }

                    /** LEGACY **/

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if (js_compteur != null) {
                        try {
                            var cnt_compteur = js_compteur.length;

                            for (var i = 0; i < cnt_compteur; i++) {
                                eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                            }
                        } catch (e) { }
                    }
                    if (js_encart != null) {
                        try {
                            js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                            eval(js_encart);
                        } catch (e) { }
                    }

                    /** END LEGACY **/

                    if (parseFloat($('#filters_menu').css('right')) === 0) {

                        toggleMenu('filters', 'right');
                    }

                    if (window.lazyload || typeof lazyload != 'undefined') {

                        lazyload.init();
                    }

                    if (typeof lazyloadImage === 'function') {
                        lazyloadImage();
                    }

                    if (typeof generateNewBlocSearchExtra === 'function') {
                        generateNewBlocProdExtra();
                    }

                } else {
                    reloadProductBlocNoResults();
                }
                filtre_ajax();

                is_in_wishlist = (typeof res[9] === 'undefined') ? [] : res[9].split(',');

                for (const product_id in is_in_wishlist) {
                    var pdt_id = (is_in_wishlist[product_id].includes('_')) ? is_in_wishlist[product_id].split('_')[0] : is_in_wishlist[product_id];
                    if ($("div").find('[data-productid=' + pdt_id + ']').attr('data-productid') !== '') {
                        var id_div = $("div").find('[data-productid=' + pdt_id + ']').attr('id');
                        list_id_div.push(id_div);
                    }
                }
            },
            complete: function () {
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                var isInfinityScroll = $('#see_all').val() == 1;
                var clickingOnSeeAllProducts = nb_product === '1000' || nb_product === 'all';

                /**
                 * Retour vers le haut du rayon :
                 * uniquement dans le cas d'une pagination classique (see_all.val() == 0).
                 * Si initialisation du scroll infini (see_all.val() == 1), on ne remonte que de quelques
                 * px pour déclencher le chargement de la page suivante.
                 */
                if (!fromScrollBottom && !fromScrollTop) {

                    if ($('.item_container:not(.push)').length <= nb_product + 1) { // Exclude push in check

                        /**
                         * Dans le cas où le scroll infini est configuré
                         * et que l'on veut l'activer
                         */
                        if (isInfinityScroll) {
                            window.scrollTo(
                                0,
                                ($('#scroll_items').offset().top // Espace entre le haut de la page et le haut du block #scroll_items
                                    + ($('#scroll_items').height() // Hauteur totale du block #scroll_items
                                        - $('.item_container').height() // Hauteur d'un item de produit
                                        * 2))
                            );
                        }

                        /**
                         * Dans le cas où le scroll infini n'est pas configuré
                         * et que l'on veut afficher tous les produits du rayon
                         */
                        else if (!isInfinityScroll && clickingOnSeeAllProducts) {
                            setTimeout(function () {
                                var itemHeight = $('.item_container:not(.push)').height(); // Hauteur d'un item de produit
                                var countPush = $('.item_container.push').length ? $('.item_container.push').length : 0;
                                var itemPushHeight = $('.item_container.push').length ? $('.item_container.push').height() : 0;
                                var changeViewEnabled = $('#toggleNumObjects').length; // Possibilité de changer de vues

                                if (changeViewEnabled) {
                                    var isViewPerOne = changeViewEnabled && $('#oneProduct').hasClass('actif'); // Vérif sur le mode de vue(s) du rayon
                                } else {
                                    var isViewPerOne = 1;
                                }

                                /**
                                 * Pour calculer le nombre de lignes de produits en vue par 2
                                 * on ajoute 1 si le nombre de produits est impair
                                 * pour avoir un réultat rond
                                 */
                                if (!(defaultNbProd % 2 == 0) && !isViewPerOne) {
                                    defaultNbProd = defaultNbProd + 1;
                                }

                                /**
                                 * La hauteur de la liste de produits
                                 * égale à la hauteur d'un item de produit
                                 * multipliée par le nombre de produits
                                 * ou le nombre de nombre de ligne de produits (en vue par 2)
                                 */
                                var defaultListItemsHeight = isViewPerOne ? itemHeight * defaultNbProd + (itemPushHeight * countPush) : itemHeight * (defaultNbProd / 2) + (itemPushHeight * countPush);

                                window.scrollTo(
                                    0,
                                    defaultListItemsHeight
                                );
                            }, 100);
                        }

                        /**
                         * Dans le cas où l'on utilise la pagination
                         */
                        else {
                            backToTop();
                        }
                    } else {
                        setTimeout(function () {
                            if ($('#see_all').length > 0 && $('#see_all').val() == 0) {

                                $("html, body").animate({
                                    scrollTop: 0
                                }, 200);

                            } else {

                                $("html, body").animate({
                                    scrollTop: $(window).scrollTop() - 2
                                }, 200);

                            }
                        }, 250);
                        //reset initialPage if from filter
                        $("#initial_page").trigger("change");
                    }
                }

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');

                    /**
                     * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                     * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                     */

                    if ($('body.category').length) {
                        var origin = $('.item_container:not(.push)').find('img.watched'),
                            target = $('span.ill_img'),
                            targetHeight = origin.height();

                        target.each(function () {
                            $(this).css({ minHeight: targetHeight });
                        })
                    }
                }

                if (fromScrollBottom && !fromScrollTop) {
                    $("#initial_page").val(page + 1);
                }

                setTimeout(function () {

                    var loader = $('.button + .loader');

                    if (loader.hasClass('loading')) {

                        loader.removeClass('loading');
                    } else {

                        loader.parent().removeClass('loading');
                    }

                    if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {

                        var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');

                        toggleMenu(menuId);
                    }
                }, 200);

                if (list_id_div.length > 0) {
                    for (const class_update in list_id_div) {
                        $("#" + list_id_div[class_update]).removeClass('addToWishlistButton');
                        $("#" + list_id_div[class_update]).addClass('existToWishlistButton');
                    }
                }
            }
        });
    }
}

function openColorAchatExpressV2() {
    var $div_color = $(this.form).find('.productColorFieldset');
    var $div_size = $(this.form).find('.productSizeFieldset');
    var div_color_actif = $div_color.hasClass('actif');

    var lightbox = $(this.form).find('.achat_express');
    var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');

    var $div_alert_stock = $(this.form).find('.bloc_add_alert');
    $div_alert_stock.hide();

    $('.achat_express').hide();
    $('.productColorFieldset.actif').removeClass('actif');
    $('.productSizeFieldset.actif').removeClass('actif');

    if (lightBoxAlertStock) {
        lightbox.addClass('displayColors');
    }
    if (div_color_actif) {
        $(this.form).find('.achat_express').hide();
        $div_color.removeClass('actif');

    } else {
        $(this.form).find('.achat_express').show();
        $div_color.addClass('actif');
        $div_size.removeClass('actif');
    }

    shadAchatExpressNotVisible();
}